/* eslint no-lone-blocks: 0 */ // --> OFF

import Util from './util';
import SquareButton from './square_button';

/**
 * A rendered box docked to one side with a pointing arrow and a text
 * @class MessageBox
 */
class MessageBox extends SquareButton {
  text: string;
  _direction: string;

  constructor(text, direction) {
    super();
    this.text = text;
    this._direction = direction;
  }

  get direction() {
    return this._direction;
  }

  set direction(direction) {
    this._direction = direction;
  }

  draw(p5, x = null, y = null) {
    const renderW = p5.renderWidth;
    const renderH = p5.renderHeight;

    let rect;

    switch (this._direction) {
      case 'bottom':
        {
          rect = {
            x: renderW / 2 - 93,
            y: renderH - 150,
            width: 200,
            height: 90
          };
        }
        break;
      case 'top':
        {
          rect = {
            x: renderW / 2 - 93,
            y: 50,
            width: 200,
            height: 90
          };
        }
        break;
      case 'right':
        {
          rect = {
            x: renderW - 450,
            y: renderH / 2 - 50,
            width: 160,
            height: 90
          };
        }
        break;
      case 'right-top':
        {
          rect = {
            x: renderW - 400,
            y: 50,
            width: 160,
            height: 90
          };
        }
        break;
      case 'left':
        {
          rect = {
            x: 50,
            y: renderH / 2 - 50,
            width: 160,
            height: 90
          };
        }
        break;
      default:
    }

    if (x != null && y != null) {
      rect.x = x;
      rect.y = y;
    }

    this.bounding.x = rect?.x ?? 0;
    this.bounding.y = rect?.y ?? 0;
    this.bounding.w = rect && rect.width !== undefined ? rect.width : 0;
    this.bounding.h = rect && rect.height !== undefined ? rect.height : 0;

    const left = this.bounding.x;
    const top = this.bounding.y;
    const right = this.bounding.x + this.bounding.w;
    const bottom = this.bounding.y + this.bounding.h;

    p5.push();
    {
      let mergePoint;
      let sidePoints;
      let tip;

      switch (this._direction) {
        case 'bottom':
          {
            const bottomOrRight = true;
            const outwardDirection = bottomOrRight ? 1 : -1;

            // For bottom tip
            mergePoint = [(right + left) / 2, bottom];
            tip = [mergePoint[0], bottom + 20 * outwardDirection];

            sidePoints = [
              [mergePoint[0] - 20, mergePoint[1]],
              [mergePoint[0] + 20, mergePoint[1]]
            ];
          }
          break;
        case 'top':
          {
            const bottomOrRight = false;
            const outwardDirection = bottomOrRight ? 1 : -1;

            // For bottom tip
            mergePoint = [(right + left) / 2, top];
            tip = [mergePoint[0], top + 20 * outwardDirection];

            sidePoints = [
              [mergePoint[0] - 20, mergePoint[1]],
              [mergePoint[0] + 20, mergePoint[1]]
            ];
          }
          break;
        case 'right-top':
        case 'right': {
          const bottomOrRight = true;
          const outwardDirection = bottomOrRight ? 1 : -1;

          // For right hand tip
          mergePoint = [right, (top + bottom) / 2];
          sidePoints = [
            [mergePoint[0], mergePoint[1] - 20],
            [mergePoint[0], mergePoint[1] + 20]
          ];
          tip = [right + 20 * outwardDirection, mergePoint[1]];
          break;
        }
        case 'left':
          {
            const bottomOrRight = false;
            const outwardDirection = bottomOrRight ? 1 : -1;

            // For right hand tip
            mergePoint = [left, (top + bottom) / 2];
            sidePoints = [
              [mergePoint[0], mergePoint[1] - 20],
              [mergePoint[0], mergePoint[1] + 20]
            ];
            tip = [left + 20 * outwardDirection, mergePoint[1]];
          }
          break;
        default:
      }

      const bgColor = p5.color('#333');

      bgColor.setAlpha(0.8 * 256);
      p5.noStroke();
      p5.fill(bgColor);
      p5.triangle(
        tip[0],
        tip[1],
        sidePoints[0][0],
        sidePoints[0][1],
        sidePoints[1][0],
        sidePoints[1][1]
      );
      p5.rect(
        this.bounding.x,
        this.bounding.y,
        this.bounding.w,
        this.bounding.h
      );

      let textColor = p5.color('white');

      p5.fill(textColor);
      p5.textSize(18);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(
        this.text,
        this.bounding.x + 10,
        this.bounding.y,
        this.bounding.w - 20,
        this.bounding.h - 8
      );

      p5.rect(this.bounding);
    }

    p5.pop();

    return Util.SUCCESS;
  }

  addListener(_) {}
}

export default MessageBox;
